import jsPDF from 'jspdf';
import autoTable from 'jspdf-autotable';
import { format } from 'date-fns';
import { it } from 'date-fns/locale';
import logo_big from './pics/logo_big.png';
import { formatString, number_to_italian_nosymbol, t } from './Util_format';
import { getCookieValue } from './App';
import { getBusinessAccounts } from './Util_API_calls';
import { getDisplayName } from './Util_app';

const generatePDF = (account_type, transactions, filters, sortedTransactions, selectedMonthYear, pageData, info) => {
  const isStatement = typeof selectedMonthYear !== 'object';
  const isCardTransaction = account_type === "card";

  const hasTransactions = Array.isArray(transactions) && transactions.length > 0;
  var sortedFilteredTransactions;

  if (hasTransactions) {
    // Convert transaction dates to Date objects and format them
    const processedTransactions = transactions.map((tx) => {
      let dateKey = isCardTransaction
        ? "transaction_created_at"
        : "transaction_date_time";
      const date = new Date(tx[dateKey]);
      let description = isCardTransaction
        ? tx.merchant_name
          ? `${t(tx.transaction_type)} ${tx.merchant_name}`.toLowerCase()
          : t(tx.transaction_type).toLowerCase()
        : tx.description;

      return {
        ...tx,
        date: !isNaN(date.getTime())
          ? format(date, "dd/MM/yyyy HH:mm", { locale: it })
          : "Invalid Date",
        originalDate: date,
        description: description,
        amount: number_to_italian_nosymbol(tx.amount),
      };
    });

    // Filter transactions based on the selectedMonthYear using 'yyyy-MM' format
    const filteredTransactions = isStatement
      ? processedTransactions.filter((tx) => {
          const transactionMonthYear = format(tx.originalDate, "yyyy-MM");
          return transactionMonthYear === selectedMonthYear;
        })
      : processedTransactions;

    // Ensure there are transactions to display
    if (filteredTransactions.length === 0) {
      console.error("No transactions found for the selected month and year.");
      return;
    }

    // Sorting transactions by date
    sortedFilteredTransactions = filteredTransactions.sort(
      (a, b) => a.originalDate - b.originalDate
    );
  }

  // const filterText = `Filters Applied: 
  //  Account: ${filters.account || 'None'}, 
  //  Date From: ${filters.dateRange && format(filters.dateRange.from, 'dd/MM/yyyy') || 'None'}, 
  //  Date To: ${filters.dateRange && format(filters.dateRange.to, 'dd/MM/yyyy') || 'None'}`;

  const headerFontSize = 18;
  const header2FontSize = 12;
  const selectedMonthYearFormatted = isStatement ? format(new Date(selectedMonthYear + '-01'), 'MMMM yyyy', { locale: it }) : `${format(selectedMonthYear.from, 'dd/MM/yyyy')} - ${format(selectedMonthYear.to, 'dd/MM/yyyy')}`;
  const pdf = new jsPDF();

  // Add Poppins font to the PDF
  pdf.addFileToVFS('Poppins-Regular.ttf');
  pdf.addFont('Poppins-Regular.ttf', 'Poppins', 'normal');

  const logoWidthMM = 510 * 0.1;
  const logoHeightMM = 510 * 0.1;

  var columns, summaryColumns, summaryBody;
  if (hasTransactions) {
    columns = [
      { header: 'Data', dataKey: 'date' },
      { header: 'Descrizione', dataKey: 'description' },
      { header: 'Importo €', dataKey: 'amount' },
    ];
  
    summaryColumns = [
      { header: 'Totale denaro in ingresso', dataKey: 'incoming' },
      { header: 'Totale denaro in uscita', dataKey: 'outgoing' },
      { header: 'Valuta', dataKey: 'currency' },
    ];
  
    const incoming = isCardTransaction
      ? transactions
          .filter(
            (e) =>
              e.amount && e.direction && e.direction.toUpperCase() === "CREDIT"
          )
          .reduce((sum, { amount }) => sum + amount, 0)
      : transactions
          .filter(
            (e) =>
              e.amount &&
              (e.transaction_type.toUpperCase() === "FUNDING" ||
                e.transaction_type.toUpperCase() === "EXCHANGE")
          )
          .reduce((sum, { amount }) => sum + amount, 0);
  
    const outgoing = isCardTransaction
      ? transactions
          .filter(
            (e) =>
              e.amount && e.direction && e.direction.toUpperCase() === "DEBIT"
          )
          .reduce((sum, { amount }) => sum + amount, 0)
      : transactions
          .filter(
            (e) =>
              e.amount &&
              (e.transaction_type.toUpperCase() === "TRANSFER" ||
                e.transaction_type.toUpperCase() === "INVOICE")
          )
          .reduce((sum, { amount }) => sum + amount, 0);
  
     summaryBody = [
      {
        incoming: number_to_italian_nosymbol(incoming),
        outgoing: number_to_italian_nosymbol(outgoing),
        currency: "EUR",
      },
    ];
  }

  autoTable(pdf, {
    columns: columns,
    body: sortedFilteredTransactions,
    startY: logoHeightMM + 70 + (isStatement ? 45 : 0),
    margin: { top: 20 },
    didDrawPage: (data) => {
      const generatedTime = format(new Date(), 'hh:mm a, dd/MM/yyyy', { locale: it });

      if (pdf.internal.getNumberOfPages() === 1) {
        // Coordinates for the top right text
        const pageWidth = pdf.internal.pageSize.getWidth();
        // Add company logo to each page
        const centerX = (pageWidth - logoWidthMM) / 2;
        pdf.addImage(logo_big, 'PNG', centerX, 10, logoWidthMM, logoHeightMM);
        // Calculate the x-coordinate to center the logo

        // details
        const headerText1 = isStatement ? 'Estratto conto del mese di ' + selectedMonthYearFormatted : `Movimenti ${selectedMonthYearFormatted}`;

        pdf.setFontSize(headerFontSize);

        const name = getDisplayName(pageData.details);
        const headerText2 = `Intestatario del conto: ${name}`;
        const headerText3 = `Indirizzo intestatario: ${pageData.details.addr_line_1}${pageData.details.addr_line_2 ? `, ${pageData.details.addr_line_2}` : ''}${pageData.details.zip_code ? `, ${pageData.details.zip_code}` : ''}${pageData.details.city ? `, ${pageData.details.city}` : ''}${pageData.details.state ? `, ${pageData.details.state}` : ''}${pageData.details.country ? `, ${pageData.details.country}` : ''}`;
        const headerText4 = isCardTransaction ? `Numero Carta: **** **** **** ${info ?? ""}` : `IBAN: ${info ?? ""}`;
        const headerText5 = `Data di generazione: ${generatedTime}`;

        let currentY = 70; // Starting Y position for the first text
        const spacing = 10; // Space between each line of text
        
        pdf.setFontSize(headerFontSize);
        pdf.text(headerText1, 15, currentY);
        currentY += spacing; // Increment Y position for the next text
        

        pdf.setFontSize(header2FontSize);
        pdf.text(headerText2, 15, currentY);
        currentY += spacing; // Increment Y position for the next text
        
        pdf.text(headerText3, 15, currentY);
        currentY += spacing; // Increment Y position for the next text
        
        pdf.text(headerText4, 15, currentY);
        currentY += spacing; // Increment Y position for the next text
        
        pdf.text(headerText5, 15, currentY);
        currentY += spacing * 1.5; // Increment Y position for the next text
        
        if (hasTransactions && isStatement) {
          pdf.setFontSize(headerFontSize);
          pdf.text("Riepilogo", 15, currentY);

          autoTable(pdf, {
            columns: summaryColumns,
            body: summaryBody,
            startY: logoHeightMM + 80,
            margin: { top: 10 },
            styles: {
              overflow: "linebreak",
              fontSize: 11,
            },
            headStyles: {
              fillColor: "#333333",
              textColor: "#FFFFFF",
              halign: "center",
              fontStyle: "bold",
            },
            columnStyles: {
              date: { cellWidth: 60 },
              amount: { cellWidth: 40, halign: "right" },
            },
            theme: "grid",
          });

          pdf.setFontSize(headerFontSize);
          pdf.text('Movimenti', 15, logoHeightMM + 70 + 40);
        }
      } else {
        pdf.setFontSize(10);
        pdf.text(
          formatString(
            t(
              isStatement
                ? isCardTransaction
                  ? "title_statement_header_card"
                  : "title_statement_header_account"
                : isCardTransaction
                ? "title_transactions_header_card"
                : "title_transactions_header_account"
            ),
            {
              month: selectedMonthYearFormatted,
              number: info ?? "N/A",
              time: generatedTime,
            }
          ),
          15,
          15
        );
      }

      // Add page number at the bottom center of each page
      let str = 'Pagina ' + pdf.internal.getNumberOfPages();
      let pageSize = pdf.internal.pageSize;
      let pageWidth = pageSize.getWidth ? pageSize.getWidth() : pageSize.width;
      pdf.setFontSize(10);
      pdf.text(str, pageWidth / 2, pageSize.height - 10, {
        align: 'center',
      });
    },
    styles: {
      overflow: 'linebreak',
      fontSize: 11,
    },
    headStyles: {
      fillColor: '#333333',
      textColor: '#FFFFFF',
      halign: 'center',
      fontStyle: 'bold',
    },
    columnStyles: {
      date: { cellWidth: 60 },
      amount: { cellWidth: 40, halign: 'right' },
    },
    theme: 'grid',
    showHead: 'everyPage',
  });

  pdf.save('statement.pdf');
};

export { generatePDF };