import React from "react";
import { Button, Dialog, DialogContent, DialogTitle, Grid, } from "@mui/material";
import { format } from "date-fns";
import { it } from "date-fns/locale";
import jsPDF from "jspdf";
import { number_to_italian_currency, t } from "./Util_format";
import logo_big from "./pics/logo_big.png";
import pdf_icon from "./pics/pdf_icon.svg";
import { getCookieValue } from "./App";
import { getBusinessAccounts } from "./Util_API_calls";
import { getDisplayName } from "./Util_app";

function Modal_TransactionDetails({ onClose, transaction, pageData, info, isCardTransaction,
}) {
  const specialTransferPrefixes = ['EPAY-BACR', 'EPAY-896', 'EPAY-BLL', 'EPAY-MAV', 'EPAY-PPA', 'EPAY-PHR', 'EPAY-RAV', 'EPAY-PIN'];

  const transactionDescription = transaction.request_id && specialTransferPrefixes.includes(transaction.request_id.split('-').slice(0, 2).join('-'))
    ? t('description_' + transaction.request_id.split('-').slice(0, 2).join('-'))
    : transaction.description ? transaction.description.toLowerCase().split(' ').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ') : transaction.transaction_type_description;

  const isEpayTransaction = transaction.request_id ? specialTransferPrefixes.includes(transaction.request_id.split('-').slice(0, 2).join('-')) : false;

  const generatePDF = () => {
    if (!pageData || !info || isCardTransaction === undefined) return;
    const headerFontSize = 20;
    const header2FontSize = 16;

    const pdf = new jsPDF();

    // Add Poppins font to the PDF
    pdf.addFileToVFS("Poppins-Regular.ttf");
    pdf.addFont("Poppins-Regular.ttf", "Poppins", "normal");

    const logoWidthMM = 510 * 0.1;
    const logoHeightMM = 510 * 0.1;

    // Coordinates for the top right text
    const pageWidth = pdf.internal.pageSize.getWidth();
    // Add company logo to each page
    const centerX = (pageWidth - logoWidthMM) / 2;
    pdf.addImage(logo_big, "PNG", centerX, 10, logoWidthMM, logoHeightMM);

    // details
    const headerText1 = isCardTransaction
      ? "Ricevuta della transazione con carta"
      : "Ricevuta della transazione del conto";

    pdf.setFontSize(headerFontSize);
    
    const name = getDisplayName(pageData.details);
    const headerText2 = `Intestatario del conto: ${name}`;
    const headerText3 = `Indirizzo intestatario: ${pageData.details.addr_line_1
      }${pageData.details.addr_line_2 ? `, ${pageData.details.addr_line_2}` : ""
      }${pageData.details.zip_code ? `, ${pageData.details.zip_code}` : ""}${pageData.details.city ? `, ${pageData.details.city}` : ""
      }${pageData.details.state ? `, ${pageData.details.state}` : ""}${pageData.details.country ? `, ${pageData.details.country}` : ""
      }`;
    const headerText4 = isCardTransaction
      ? `Numero Carta: **** **** **** ${info}`
      : `IBAN: ${info}`;
    const headerText5 = `Data di generazione: ${format(
      new Date(),
      "hh:mm a, dd/MM/yyyy",
      { locale: it }
    )}`;

    let currentY = 70; // Starting Y position for the first text
    const spacing = 10; // Space between each line of text

    pdf.setFontSize(headerFontSize);
    pdf.text(headerText1, 15, currentY);
    currentY += spacing; // Increment Y position for the next text

    pdf.setFontSize(header2FontSize);
    pdf.text(headerText2, 15, currentY);
    currentY += spacing; // Increment Y position for the next text

    pdf.text(headerText3, 15, currentY);
    currentY += spacing; // Increment Y position for the next text

    pdf.text(headerText4, 15, currentY);
    currentY += spacing; // Increment Y position for the next text

    pdf.text(headerText5, 15, currentY);
    currentY += spacing * 1.5; // Increment Y position for the next text

    pdf.setFontSize(headerFontSize);
    pdf.text("Ricevuta della transazione", 15, currentY);
    currentY += spacing;

    pdf.setFontSize(header2FontSize);

    pdf.text(
      `Tipo di trasferimento: ${t(transaction.transaction_type)}`,
      15,
      currentY
    );
    currentY += spacing;

    pdf.text(
      `Importo: ${number_to_italian_currency(transaction.amount)}`,
      15,
      currentY
    );
    currentY += spacing;

    pdf.text(`Causale: ${transactionDescription}`, 15, currentY);
    currentY += spacing;

    if (isCardTransaction) {
      if (transaction.merchant_name) {
        pdf.text(
          `Nome del commerciante: ${transaction.merchant_name}`,
          15,
          currentY
        );
        currentY += spacing;
      }
    } else if (! isEpayTransaction) {
      pdf.text(`IBAN del mittente: ${transaction.account_from}`, 15, currentY);
      currentY += spacing;
      if (transaction.sender_name) {
        pdf.text(
          `Nome dell' ordinante: ${transaction.sender_name}`,
          15,
          currentY
        );
        currentY += spacing;
      }
      pdf.text(
        `IBAN del beneficiario: ${transaction.account_to}`,
        15,
        currentY
      );
      currentY += spacing;
    }

    pdf.text(
      `Data Valuta: ${new Date(
        transaction.transaction_date_time ?? transaction.created_at
      ).toLocaleDateString()}`,
      15,
      currentY
    );
    currentY += spacing;

    // Save the PDF with a dynamic file name
    const pdfFileName = `Transaction_${transaction.transaction_id}.pdf`;
    pdf.save(pdfFileName);
  };
  // The Dialog's open state depends on whether the transaction prop is not null

  console.log(transaction);
  return (
    <Dialog open={!!transaction} onClose={onClose} maxWidth="sm" fullWidth>
      <DialogTitle className="modal_title">
        {t("transaction_receipt")}
      </DialogTitle>
      <DialogContent>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            {t("amount")}
          </Grid>

          <Grid item xs={6}>
            {number_to_italian_currency(transaction.amount)}
          </Grid>

          <Grid item xs={6}>
            {t("transfer_type")}
          </Grid>

          <Grid item xs={6}>
            {t(transaction.transaction_type)}
          </Grid>

          <Grid item xs={6}>
            {t("status")}
          </Grid>

          <Grid item xs={6}>
            {transaction.status && t(transaction.status)}
            {transaction.transaction_status &&
              t(
                `card_transaction_status_${transaction.transaction_status.toLowerCase()}`
              )}
          </Grid>

          {! isEpayTransaction && transaction.sender_name && (
            <Grid item xs={6}>
              {t("sender_name")}
            </Grid>
          )}

          {! isEpayTransaction && transaction.sender_name && (
            <Grid item xs={6}>
              {transaction.sender_name}
            </Grid>
          )}

          {! isEpayTransaction &&
            transaction.beneficiary_name &&
            transaction.beneficiary_surname && (
              <Grid item xs={6}>
                {t("beneficiary_name_title")}
              </Grid>
            )}

          {! isEpayTransaction &&
            transaction.beneficiary_name &&
            transaction.beneficiary_surname && (
              <Grid item xs={6}>
                {`${transaction.beneficiary_name} ${transaction.beneficiary_surname}`}
              </Grid>
            )}

          {transaction.merchant_id && (
            <Grid item xs={6}>
              {t("merchant_id")}
            </Grid>
          )}

          {transaction.merchant_id && (
            <Grid item xs={6}>
              {transaction.merchant_id}
            </Grid>
          )}

          {transaction.merchant_name && (
            <Grid item xs={6}>
              {t("merchant_name")}
            </Grid>
          )}

          {transaction.merchant_name && (
            <Grid item xs={6}>
              {transaction.merchant_name}
            </Grid>
          )}

          {! isEpayTransaction && transaction.account_from && (
            <Grid item xs={6}>
              {t("sender_iban")}
            </Grid>
          )}

          {! isEpayTransaction && transaction.account_from && (
            <Grid item xs={6}>
              {transaction.account_from}
            </Grid>
          )}

          {! isEpayTransaction && transaction.account_to && (
            <Grid item xs={6}>
              {t("receiver_iban")}
            </Grid>
          )}

          {! isEpayTransaction && transaction.account_to && (
            <Grid item xs={6}>
              {transaction.account_to}
            </Grid>
          )}

          <Grid item xs={6}>
            {t("description")}
          </Grid>

          <Grid item xs={6}>
            {transactionDescription}
          </Grid>

          <Grid item xs={6}>
            {t("payment_date")}
          </Grid>

          <Grid item xs={6}>
            {new Date(
              transaction.transaction_created_at ??
              transaction.transaction_date_time ??
              transaction.created_at
            ).toLocaleDateString()}
          </Grid>

          <Grid item xs={6}>
            {t("transaction_id")}
          </Grid>

          <Grid item xs={6}>
            {transaction.transaction_id}
          </Grid>
        </Grid>
      </DialogContent>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          margin: "20px 0",
        }}
      >
        <Button
          startIcon={
            <img
              src={pdf_icon}
              alt="PDF"
              style={{ height: "30px", width: "30px" }}
            />
          }
          onClick={generatePDF}
          style={{ height: "30px", minWidth: "30px", padding: "0 12px" }}
        >
          Download PDF
        </Button>
      </div>
    </Dialog>
  );
}

export default Modal_TransactionDetails;
