import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate, useNavigate, useLocation, } from 'react-router-dom';
import firebaseConfig from './config/firebase_config.json';
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import flagit from './pics/it.webp';
import flagen from './pics/en.webp';
import { IntercomProvider, useIntercom } from 'react-use-intercom';
import config from './config/env.json';
import './css/App.css';
import "vanilla-cookieconsent/dist/cookieconsent.css";

// Unlogged-ONLY Pages
import Page_Login from './Page_Login';
import Page_Login_otp from './Page_Login_otp';
import Page_Waiting_Push from './Page_Waiting_Push';
import Page_Register from './Page_Register';
import Page_Kyc from './Page_Kyc';
import Page_Kyb from './Page_Kyb';
import Page_Reset_my_password from './Page_Reset_my_password';
import Page_Auth_action from './Page_Auth_action';
import Page_Kill_sessions from './Page_Kill_sessions';

// Logged-ONLY Pages
import Structure_logged from './Structure_logged';
import Page_Identification from './Page_Identification';
import Page_New_account from './Page_New_account';
import Page_Home from './Page_Home';
import Page_Profile from './Page_Profile';
import Page_Cards from './Page_Cards';
import Page_Resend_registration_email from './Page_Resend_registration_email';
import Page_Banktransfers from './Page_Banktransfers';
import Page_Transactions from './Page_Transactions';
import Page_Statements from './Page_Statements';
import Page_Contactus from './Page_Contactus';
import Page_Services from './Page_Services';
import { LinkGeneratePDF } from './Util_link_generate_PDF';


// Logged and Unlogged Pages
import Page_maintenance from './Page_maintenance';
import * as CookieConsent from "vanilla-cookieconsent";
import { getCookieConsentConfig } from './utils/CookieConsentConfig';
import Modal_ConfirmCookieDecline from './Modal_ConfirmCookieDecline';
import { Backdrop } from '@mui/material';
import { changeUserLanguage } from './Util_API_calls';
import { Helmet } from 'react-helmet';
import { t } from './Util_format';
import { getAuth } from '@firebase/auth';
import { getFirestore } from 'firebase/firestore';

const RedirectIfNoCookie = () => {
    const cookieRow = document.cookie.split('; ').find(row => row.startsWith('YourCookieName='));
    const cookieValue = cookieRow ? cookieRow.split('=')[1] : null;

    if (!cookieValue) {
        return <Navigate to="/login" />;
    } else {
        return null;
    }
}

const LanguageFlagWrapper = () => {
    const location = useLocation();

    switch (location.pathname) {
        case "/login":
        case "/login_otp":
        case "/waiting_push":
        case "/register":
        case "/auth/action":
        case "/Page_Reset_my_password":
        case "/resend_registration_email":
        case "/kyc":
            break;
        default:
            return null; // Return nothing for any other routes
    }

    return (
        <div className="language-flags">
            <img
                src={flagit}
                alt="Italian"
                title="Sito in Italiano"
                onClick={() => changeLanguage('it')}
            />
            <img
                src={flagen}
                alt="English"
                title="Change language to English"
                onClick={() => changeLanguage('en')}
            />
        </div>
    );
};

const SessionManager = () => {
    const navigate = useNavigate();

    // This function will set a new cookie with the updated expiration time
    const resetSessionTimer = () => {
        // Set the expiration time to 5 minutes from the current time
        const newExpirationTime = new Date(new Date().getTime() + config.INACTIVITY_LOGOUT_TIME_IN_MINUTES * 60000).toUTCString();
        setCookieValue('inactivity_session_expiration_time', newExpirationTime);
        console.log('Session reset at:', newExpirationTime);
    };

    useEffect(() => {
        const isKybPage = window.location.pathname.startsWith('/kyb');
        const isKycPage = window.location.pathname.startsWith('/kyc');
        // This function is called when user interaction is detected
        const handleUserAction = () => {
            resetSessionTimer(); // Reset the session timer upon user action
        };

        // Set up event listeners for user interaction
        window.addEventListener('click', handleUserAction);
        window.addEventListener('keydown', handleUserAction);

        // This interval will check for session expiration
        const interval = setInterval(() => {
            const currentTime = new Date().getTime();

            // Get the stored cookie values for firebase and inactivity expiration
            const firebaseExpirationTime = getCookieValue('firebase_session_expiration_time');
            const inactivityExpirationTime = getCookieValue('inactivity_session_expiration_time');

            // Calculate time left before expiration
            const firebaseTimeLeft = (new Date(firebaseExpirationTime).getTime() - currentTime) / 1000;
            const inactivityTimeLeft = (new Date(inactivityExpirationTime).getTime() - currentTime) / 1000;

            // Check if either session has expired
            if (firebaseTimeLeft <= 0 || (!isKybPage && !isKycPage && inactivityTimeLeft <= 0)) {
                clearInterval(interval); // Stop the interval
                deleteCookie();          // Clear all cookies
                navigate('/login?action=clean-cookie&reason=session_expired');      // Redirect to the login page
            } else {
                // Optionally log the time left for each session (for debugging purposes)
                if (!isNaN(firebaseTimeLeft)) {
                    //        console.log(`Firebase time left: ${Math.floor(firebaseTimeLeft)} seconds`);
                }
                if (!isNaN(inactivityTimeLeft)) {
                    //        console.log(`Inactivity time left: ${Math.floor(inactivityTimeLeft)} seconds`);
                }
            }
        }, 1000);

        // This will be called when the component unmounts
        return () => {
            if (isKybPage) {
                resetSessionTimer();
            }
            clearInterval(interval); // Clear the interval
            // Remove event listeners to prevent memory leaks
            window.removeEventListener('click', handleUserAction);
            window.removeEventListener('keydown', handleUserAction);
        };
    }, [navigate]); // Only re-run the effect if navigate changes

    return null; // This component doesn't render anything
};

function LoggedInWrapper({ dashboard_structure, PageContent }) {
    return (
        <div>
            {React.cloneElement(dashboard_structure, { children: PageContent })}
        </div>
    );
}

export const changeLanguage = (language) => {
    localStorage.setItem("change_user_language", "true");
    document.cookie = `language=${language}; path=/; samesite=strict`;
    window.location.reload();
}

export const setCookieValue = (name, value) => {
    document.cookie = `${name}=${value}; path=/; samesite=strict`;
};

export const getCookieValue = (name) => {
    let matches = document.cookie.match(new RegExp(
        "(?:^|; )" + name.replace(/([\.$?*|{}\(\)\[\]\\\/\+^])/g, '\\$1') + "=([^;]*)"
    ));
    return matches ? decodeURIComponent(matches[1]) : undefined;
};

export const deleteCookie = () => {
    const cookies = document.cookie.split(";");

    for (let i = 0; i < cookies.length; i++) {
        const cookie = cookies[i];
        const eqPos = cookie.indexOf("=");
        const name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
        document.cookie = name + "=; expires=Thu, 01 Jan 1970 00:00:01 GMT; path=/;";
    }
};

export const deleteSpecificCookie = (name) => {
    document.cookie = name + '=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;';
};

const App = () => {

    const [APIPageData, setAPIPageData] = useState(null);
    const [isOpenCookieDeclineConfirm, setOpenCookieDeclineConfirm] = useState(false);
    const [isDisableCookie, setDisableCookie] = useState(false);

    const onCloseCookieDeclineConfirm = (isAccept) => {
        setOpenCookieDeclineConfirm(false);
        if (isAccept) {
            CookieConsent.acceptCategory([]);
        }
    }

    const handleUpdateNotificationBell = (PageData) => {
        if (document.getElementById('page_dashboard')) {
            if (PageData && typeof PageData === 'object') {
                setAPIPageData(PageData);
                return PageData;
            } else {
                console.log("PageData is not an object.");
            }
        } else {
            console.log("Element with ID 'page_dashboard' does not exist.");
        }
        return 0;
    };

    useEffect(() => {
        if (localStorage.getItem("change_user_language") === "true") {
            changeUserLanguage(getCookieValue("language") === "en" ? "en" : "it");
        }
        setDisableCookie(!CookieConsent.acceptedCategory("technical"));
        CookieConsent.run({
            ...getCookieConsentConfig(
                getCookieValue("language") === "en" ? "en" : "it"
            ),
            onConsent: ({ cookie }) => {
                if (!cookie.categories.find((e) => e === "technical")) {
                    deleteCookie();
                    setDisableCookie(true);
                } else {
                    setDisableCookie(false);
                }
            },
            onModalReady: ({ modalName, modal }) => {
                if (modalName === "consentModal") {
                    modal
                        .querySelector('[type="button"][data="accept"][class="cm__btn"]')
                        .addEventListener("click", () => {
                            CookieConsent.acceptCategory("all");
                            CookieConsent.hide();
                            setDisableCookie(false);
                        });
                    modal
                        .querySelector('[type="button"][data="decline"][class="cm__btn"]')
                        .addEventListener("click", () => {
                            setOpenCookieDeclineConfirm(true);
                        });
                    modal
                        .querySelector(
                            '[type="button"][data="setting"][class="cm__btn cm__btn--secondary"]'
                        )
                        .addEventListener("click", () => {
                            CookieConsent.showPreferences();
                        });
                }
            },
        });
    }, []);

    return (
        <IntercomProvider appId={config.INTERCOM_APP_ID}>
            <Helmet>
                <meta name="smartbanner:title" content="Carta Reale" />
                <meta name="smartbanner:author" content="First Digital Trade Ltd" />
                {/* <meta name="smartbanner:price" content={t('app_description')} /> */}
                <meta name="smartbanner:button" content={t('view')} />
                <meta name="smartbanner:close-label" content={t('close')} />
            </Helmet>
            <Router>
                <Backdrop
                    sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                    open={isDisableCookie}
                />
                <Modal_ConfirmCookieDecline open={isOpenCookieDeclineConfirm} onClose={onCloseCookieDeclineConfirm} />
                <SessionManager />
                <LanguageFlagWrapper />
                <Routes>
                    <Route path="/" element={<RedirectIfNoCookie />} />
                    <Route path="/login" element={<Page_Login />} />
                    <Route path="/login_otp" element={<Page_Login_otp />} />
                    <Route path="/waiting_push" element={<Page_Waiting_Push />} />
                    <Route path="/kyc" element={<Page_Kyc />} />
                    <Route path="/kyb" element={<Page_Kyb />} />
                    <Route path="/kyb/:business_id" element={<Page_Kyb />} />
                    <Route path="/kyb/new/:business_name" element={<Page_Kyb />} />
                    <Route path="/register" element={<Page_Register />} />
                    <Route path="/new_account" element={<Page_New_account />} />
                    <Route path="/new_account/:type" element={<Page_New_account />} />
                    <Route path="/identification" element={<Page_Identification />} />
                    <Route path="/auth/action" element={<Page_Auth_action />} />
                    <Route path="/auth/reset-my-password" element={<Page_Reset_my_password />} />
                    <Route path="/auth/resend-registration-email" element={<Page_Resend_registration_email />} />
                    <Route path="/kill_sessions" element={<Page_Kill_sessions />} />
                    <Route path="/kill_session" element={<Page_Kill_sessions />} />
                    <Route path="/maintenance" element={<Page_maintenance />} />
                    <Route
                        path="/home"
                        element={
                            <LoggedInWrapper
                                dashboard_structure={<Structure_logged APIPageData={APIPageData} />}
                                PageContent={<Page_Home onDataChange={handleUpdateNotificationBell} />}
                            />
                        }
                    />
                    <Route
                        path="/profile"
                        element={
                            <LoggedInWrapper
                                dashboard_structure={<Structure_logged APIPageData={APIPageData} />}
                                PageContent={<Page_Profile onDataChange={handleUpdateNotificationBell} />}
                            />
                        }
                    />
                    <Route
                        path="/cards"
                        element={
                            <LoggedInWrapper
                                dashboard_structure={<Structure_logged APIPageData={APIPageData} />}
                                PageContent={<Page_Cards onDataChange={handleUpdateNotificationBell} />}
                            />
                        }
                    />
                    <Route
                        path="/cards/recharge"
                        element={
                            <LoggedInWrapper
                                dashboard_structure={<Structure_logged APIPageData={APIPageData} />}
                                PageContent={<Page_Cards />}
                            />
                        }
                    />
                    <Route
                        path="/cards/recharge/:url_card_id"
                        element={
                            <LoggedInWrapper
                                dashboard_structure={<Structure_logged APIPageData={APIPageData} />}
                                PageContent={<Page_Cards />}
                            />
                        }
                    />
                    <Route
                        path="/cards/withdraw"
                        element={
                            <LoggedInWrapper
                                dashboard_structure={<Structure_logged APIPageData={APIPageData} />}
                                PageContent={<Page_Cards />}
                            />
                        }
                    />
                    <Route
                        path="/cards/withdraw/"
                        element={
                            <LoggedInWrapper
                                dashboard_structure={<Structure_logged APIPageData={APIPageData} />}
                                PageContent={<Page_Cards />}
                            />
                        }
                    />
                    <Route
                        path="/cards/withdraw/:url_card_id"
                        element={
                            <LoggedInWrapper
                                dashboard_structure={<Structure_logged APIPageData={APIPageData} />}
                                PageContent={<Page_Cards />}
                            />
                        }
                    />
                    <Route
                        path="/cards/edit/:url_card_id"
                        element={
                            <LoggedInWrapper
                                dashboard_structure={<Structure_logged APIPageData={APIPageData} />}
                                PageContent={<Page_Cards />}
                            />
                        }
                    />
                    <Route
                        path="/cards/order"
                        element={
                            <LoggedInWrapper
                                dashboard_structure={<Structure_logged APIPageData={APIPageData} />}
                                PageContent={<Page_Cards />}
                            />
                        }
                    />
                    <Route
                        path="/cards/recharge/"
                        element={
                            <LoggedInWrapper
                                dashboard_structure={<Structure_logged APIPageData={APIPageData} />}
                                PageContent={<Page_Cards onDataChange={handleUpdateNotificationBell} />}
                            />
                        }
                    />
                    <Route
                        path="/cards/edit/"
                        element={
                            <LoggedInWrapper
                                dashboard_structure={<Structure_logged APIPageData={APIPageData} />}
                                PageContent={<Page_Cards onDataChange={handleUpdateNotificationBell} />}
                            />
                        }
                    />
                    <Route
                        path="/bank-transfers"
                        element={
                            <LoggedInWrapper
                                dashboard_structure={<Structure_logged APIPageData={APIPageData} />}
                                PageContent={<Page_Banktransfers onDataChange={handleUpdateNotificationBell} />}
                            />
                        }
                    />
                    <Route
                        path="/bank-transfers/make-payment/"
                        element={
                            <LoggedInWrapper
                                dashboard_structure={<Structure_logged APIPageData={APIPageData} />}
                                PageContent={<Page_Banktransfers onDataChange={handleUpdateNotificationBell} />}
                            />
                        }
                    />
                    <Route
                        path="/bank-transfers/create-beneficiary/"
                        element={
                            <LoggedInWrapper
                                dashboard_structure={<Structure_logged APIPageData={APIPageData} />}
                                PageContent={<Page_Banktransfers onDataChange={handleUpdateNotificationBell} />}
                            />
                        }
                    />
                    <Route
                        path="/bank-transfers/new/"
                        element={
                            <LoggedInWrapper
                                dashboard_structure={<Structure_logged APIPageData={APIPageData} />}
                                PageContent={<Page_Banktransfers onDataChange={handleUpdateNotificationBell} />}
                            />
                        }
                    />
                    <Route
                        path="/bank-transfers/make-payment/:url_beneficiary_id"
                        element={
                            <LoggedInWrapper
                                dashboard_structure={<Structure_logged APIPageData={APIPageData} />}
                                PageContent={<Page_Banktransfers onDataChange={handleUpdateNotificationBell} />}
                            />
                        }
                    />
                    <Route
                        path="/transactions"
                        element={
                            <LoggedInWrapper
                                dashboard_structure={<Structure_logged APIPageData={APIPageData} />}
                                PageContent={<Page_Transactions onDataChange={handleUpdateNotificationBell} />}
                            />
                        }
                    />
                    <Route
                        path="/statements"
                        element={
                            <LoggedInWrapper
                                dashboard_structure={<Structure_logged APIPageData={APIPageData} />}
                                PageContent={<Page_Statements onDataChange={handleUpdateNotificationBell} />}
                            />
                        }
                    />
                    <Route
                        path="/user-guide"
                        element={
                            <LoggedInWrapper
                                dashboard_structure={<Structure_logged onDataChange={handleUpdateNotificationBell} />}
                            />
                        }
                    />
                    <Route
                        path="/contact-us"
                        element={
                            <LoggedInWrapper
                                dashboard_structure={<Structure_logged APIPageData={APIPageData} />}
                                PageContent={<Page_Contactus onDataChange={handleUpdateNotificationBell} />}
                            />
                        }
                    />
                    <Route
                        path="/services"
                        element={
                            <LoggedInWrapper
                                dashboard_structure={<Structure_logged APIPageData={APIPageData} />}
                                PageContent={<Page_Services />}
                            />
                        }
                    />
                    <Route
                        path="/services/:url_category_name"
                        element={
                            <LoggedInWrapper
                                dashboard_structure={<Structure_logged APIPageData={APIPageData} />}
                                PageContent={<Page_Services />}
                            />
                        }
                    />
                    <Route
                        path="/services/:url_category_name/:url_supplier_name"
                        element={
                            <LoggedInWrapper
                                dashboard_structure={<Structure_logged APIPageData={APIPageData} />}
                                PageContent={<Page_Services />}
                            />
                        }
                    />
                    <Route
                        path="/services/:url_category_name/:url_supplier_name/:url_ean"
                        element={
                            <LoggedInWrapper
                                dashboard_structure={<Structure_logged APIPageData={APIPageData} />}
                                PageContent={<Page_Services />}
                            />
                        }
                    />
                    <Route path="/generate-statement/bankaccount/:bankAccountId/:year/:month" element={<LinkGeneratePDF />} />
                    <Route path="/generate-statement/card/:cardId/:year/:month" element={<LinkGeneratePDF />} />
                </Routes>
            </Router>
        </IntercomProvider>
    );
}

// Initialize Firebase
export const app = initializeApp(firebaseConfig);
getAnalytics(app);
export const auth = getAuth(app);
export const db = getFirestore(app);

export default App;