import { faCreditCard, faMobileAlt } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Button,
  Card,
  Checkbox,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  MenuItem,
  Select,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Typography,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import TextField from "@mui/material/TextField";
import { Autocomplete, LoadScript } from "@react-google-maps/api";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { getCookieValue, setCookieValue } from "./App";
import config from "./config/env.json";
import Modal_Waiting_Push from "./Modal_Waiting_Push";
import AppleGooglePayLogos from "./pics/AppleGooglePayLogos.svg";
import cardb from "./pics/cardb.webp";
import cardw from "./pics/cardw.webp";
import { fetchBankAccountBalance, get_api_url, isBusinessAccount } from "./Util_API_calls";
import { t } from "./Util_format";
import { sendOTPDoubleAuth, verifyOTPDoubleAuth } from "./Util_OTP_doubleauth";

function Modal_StartCardOrder({
  onClose,
  closeModal,
  UserAccountIDFrom,
  card_accounts,
  userDetails,
}) {
  const ownedVirtualBlueCount = card_accounts.filter(
    (card) =>
      card.card_type.toLowerCase() === "virtual" &&
      card.card_color.toLowerCase() === "blue"
  ).length;
  const ownedVirtualWhiteCount = card_accounts.filter(
    (card) =>
      card.card_type.toLowerCase() === "virtual" &&
      card.card_color.toLowerCase() === "white"
  ).length;
  const ownedPlasticBlueCount = card_accounts.filter(
    (card) =>
      card.card_type.toLowerCase() === "plastic" &&
      card.card_color.toLowerCase() === "blue"
  ).length;
  const ownedPlasticWhiteCount = card_accounts.filter(
    (card) =>
      card.card_type.toLowerCase() === "plastic" &&
      card.card_color.toLowerCase() === "white"
  ).length;
  const hasBlueCard = ownedVirtualBlueCount + ownedPlasticBlueCount > 0;

  // console.log('ownedVirtualBlueCount', ownedVirtualBlueCount, 'ownedVirtualWhiteCount', ownedVirtualWhiteCount, 'ownedPlasticBlueCount', ownedPlasticBlueCount, 'ownedPlasticWhiteCount', ownedPlasticWhiteCount);
  const navigate = useNavigate();
  const [ModalStepNumber, setModalStepNumber] = useState(false);
  const [otp, setOtp] = useState(Array(6).fill(""));
  const [isProcessing, setIsProcessing] = useState(false);
  const inputsRef = useRef([]);
  const [errorMessage, setErrorMessage] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const step3_submitButtonRef = useRef(null);
  const DoubleAuthOrderCardExp = getCookieValue("DoubleAuthOrderCardExp");
  const [bank_account_balance, setbank_account_balance] = useState(null);
  const [selectedCardToBuy, setselectedCardToBuy] = useState({
    type: null,
    color: null,
    cost: null,
  });
  const hasInsufficientFunds =
    bank_account_balance !== null &&
    bank_account_balance - selectedCardToBuy.cost < 0;
  const [autocomplete, setAutocomplete] = useState(null);
  const [cardTypeConfirmed, setCardTypeConfirmed] = useState(false);
  const [acceptTerms, setAcceptTerms] = useState(false);
  const libraries = ["places"]; //

  const CardTypeTag = styled(Card)(({ type }) => ({
    margin: 8,
    padding: 30,
    height: "320px",
    width: "250px",
    display: "flex",
    flexDirection: "column",
    textAlign: "center",
    alignItems: "stretch",
    backgroundColor: selectedCardToBuy.type === type ? "#e0f1f9" : "white",
    border:
      selectedCardToBuy.type === type
        ? "1px solid #2f2f2f"
        : "1px solid #f0f0f0",
    cursor: "pointer",
    transition: "background-color 0.3s ease", // Fade-in effect

    "&:hover": {
      backgroundColor: "#e0f1f9",
      border: "1px solid #2f2f2f",
    },
  }));

  const CardColorTag = styled(Card)(({ color }) => ({
    margin: 8,
    padding: 30,
    display: "flex",
    flexDirection: "column",
    textAlign: "center",
    alignItems: "stretch",

    height: "320px",

    width: "250px",
    backgroundColor: selectedCardToBuy.color === color ? "#e0f1f9" : "white",
    border:
      selectedCardToBuy.color === color
        ? "1px solid #2f2f2f"
        : "1px solid #f0f0f0",
    cursor: "pointer",
    transition: "background-color 0.3s ease", // Fade-in effect

    "&:hover": {
      backgroundColor: "#e0f1f9",
      border: "1px solid #2f2f2f",
    },
  }));

  const styles = {
    otpInputsContainer: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      flexDirection: "row",
      width: "100%",
    },
    otpInput: {
      textAlign: "center",
      width: "2ch",
    },
    step_description: {
      paddingBottom: "20px",
    },
    card_title: {
      font: "Poppins",
      color: "#333333",
      textAlign: "left",
    },
    card_pic: {
      marginRight: "20px",
      color: "#333333",
    },
  };

  // This function is triggered when a card type is selected
  const handleCardTypeSelection = (cardType) => {
    setErrorMessage("");
    if (
      cardType === "plastic" &&
      ownedPlasticBlueCount >= 1 &&
      ownedPlasticWhiteCount >= 1
    ) {
      setErrorMessage(t("you_already_own_a_one_plastic_card_of_each_color"));
      setselectedCardToBuy({ type: null }); // Update both type and cost together
      return;
    }
    if (
      cardType === "virtual" &&
      ownedVirtualBlueCount >= 7 &&
      ownedVirtualWhiteCount >= 7
    ) {
      setErrorMessage(t("you_reached_the_limit_on_virtual_card_of_each_color"));
      setselectedCardToBuy({ type: null }); // Update both type and cost together
      return;
    }
    setselectedCardToBuy({ type: cardType }); // Update both type and cost together
  };

  const handleCardColorSelection = (cardColor) => {
    setErrorMessage("");

    if (
      selectedCardToBuy.type === "plastic" &&
      cardColor == "white" &&
      ownedPlasticWhiteCount >= 2
    ) {
      setErrorMessage(t("error_message_max_order_plastic_card"));
      setselectedCardToBuy((prevType) => ({
        ...prevType,
        type: selectedCardToBuy.type,
        color: null,
        cost: null,
      }));
      return;
    }
    if (
      selectedCardToBuy.type === "plastic" &&
      cardColor == "blue" &&
      ownedPlasticBlueCount >= 2
    ) {
      setErrorMessage(t("error_message_max_order_plastic_card"));
      setselectedCardToBuy((prevType) => ({
        ...prevType,
        type: selectedCardToBuy.type,
        color: null,
        cost: null,
      }));
      return;
    }

    if (
      selectedCardToBuy.type === "virtual" &&
      cardColor == "white" &&
      ownedVirtualWhiteCount >= 7
    ) {
      setErrorMessage(t("you_reached_the_limit_on_virtual_card_of_this_color"));
      setselectedCardToBuy((prevType) => ({
        ...prevType,
        type: selectedCardToBuy.type,
        color: null,
        cost: null,
      }));
      return;
    }
    if (
      selectedCardToBuy.type === "virtual" &&
      cardColor == "blue" &&
      ownedPlasticBlueCount >= 7
    ) {
      setErrorMessage(t("you_reached_the_limit_on_virtual_card_of_this_color"));
      setselectedCardToBuy((prevType) => ({
        ...prevType,
        type: selectedCardToBuy.type,
        color: null,
        cost: null,
      }));
      return;
    }

    let cost;
    switch (cardColor) {
      case "white":
        cost =
          selectedCardToBuy.type === "virtual"
            ? config.CARD_ORDER_FEES_VIRTUAL_WHITE
            : config.CARD_ORDER_FEES_PHYSICAL_WHITE;
        break;
      case "blue":
        cost =
          selectedCardToBuy.type === "virtual"
            ? config.CARD_ORDER_FEES_VIRTUAL_BLUE
            : config.CARD_ORDER_FEES_PHYSICAL_BLUE;
        break;
    }
    // alert(selectedCardToBuy.type);
    setselectedCardToBuy((prevType) => ({
      ...prevType,
      type: selectedCardToBuy.type,
      color: cardColor,
      cost: cost,
    }));
  };

  const restart_Order = () => {
    setModalStepNumber(1);
  };

  const step2_review_order_details = async () => {
    setErrorMessage("");
    setSuccessMessage("");
    if (acceptTerms === false) {
      setErrorMessage(t("please_accept_terms_order_card"));
      return;
    }
    setIsProcessing(true);
    const [balance] = await Promise.all([
      fetchBankAccountBalance(UserAccountIDFrom),
    ]);
    setIsProcessing(false);
    // Update the state with the fetched data
    setbank_account_balance(balance);
    setModalStepNumber(2);
  };

  const step3_handleSendOTPDoubleAuth = async (isForceSms) => {
    setIsProcessing(true);
    // close google autocomplete if open
    if (autocomplete) {
      window.google.maps.event.clearInstanceListeners(autocomplete);
      setAutocomplete(null);
    }

    if (DoubleAuthOrderCardExp) {
      const expirationDate = new Date(DoubleAuthOrderCardExp);
      const currentDate = new Date();
      const difference = expirationDate - currentDate;
      const minutesLeft = Math.round(difference / 60000);
      if (minutesLeft > 0) {
        setModalStepNumber(4);
        step4_handleOrderSubmit();
        return;
      }
    }

    setErrorMessage("");
    setSuccessMessage("");

    const token = getCookieValue("firebaseToken");
    try {
      const response = await sendOTPDoubleAuth(
        "baas_accounts_card_create",
        isForceSms
      );
      setModalStepNumber(3);
    } catch (error) {
      const errorMsg =
        error.message === "too_many_requests_try_later"
          ? t("too_many_requests_try_later")
          : t(error.message);
      setErrorMessage(errorMsg);
      setSuccessMessage("");
    } finally {
      setIsProcessing(false);
    }
  };

  const step4_handleVerifyOTPDoubleAuth = async () => {
    setErrorMessage("");
    setSuccessMessage("");
    setIsProcessing(true);
    const token = getCookieValue("firebaseToken");

    try {
      const response = await verifyOTPDoubleAuth(
        "baas_accounts_card_create",
        otp.join(""),
        token
      );
      setModalStepNumber(4);
      step4_handleOrderSubmit();
    } catch (error) {
      let errorMsg;
      switch (error.message) {
        case "too_many_requests_try_later":
          errorMsg = t("too_many_requests_try_later");
          break;
        case "wrong_otp_code":
          errorMsg = t("wrong_otp_code");
          setOtp(Array(6).fill(""));
          break;
        default:
          errorMsg = t(error.message);
          break;
      }
      setErrorMessage(errorMsg);
      setSuccessMessage("");
    } finally {
      setIsProcessing(false);
      navigate("/cards/");
    }
  };

  const step4_handleOrderSubmit = async (isTestAction = false) => {
    if (!isTestAction) {
      setErrorMessage("");
      setSuccessMessage("");
      setIsProcessing(true);
    }
    const token = getCookieValue("firebaseToken");
    const clean_amount = 10000000;

    const addressPayload =
      selectedCardToBuy.type === "virtual"
        ? {
            shipping_addr_line_1: "virtual Address 123",
            shipping_city: "virtual City",
            shipping_state: "virtual State",
            shipping_zip: "999999",
            shipping_country: "ITA",
          }
        : {
            shipping_addr_line_1: formValues.addr_line_1,
            shipping_city: formValues.city,
            shipping_state: formValues.state,
            shipping_zip: formValues.zip_code,
            shipping_country: formValues.country,
          };

    try {
      setCookieValue("DoubleAuthOrderCardExp", new Date(Date.now()));
      const API_URL = await get_api_url();
      const response = await fetch(`${API_URL}/api/baas/create/card`, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({
          card_type: selectedCardToBuy.type.toLowerCase(),
          card_color: selectedCardToBuy.color.toLowerCase(),
          currency: "EUR",
          ...addressPayload,
        }),
      });

      if (!response.ok) {
        if (isTestAction) {
          return;
        }
        const responseData = await response.json(); // Convert response body to JSON
        if (response.status === 500) {
          throw new Error("unexpected_error");
        }
        if (response.status === 429) {
          throw new Error("pleasewait30secs");
        }
        if (
          response.status === 400 &&
          responseData.details ===
            "You have reached the maximum number of cards"
        ) {
          throw new Error("too_many_cards");
        }
        throw new Error(`HTTP error! Status: ${response.status}`);
      }
      if (isTestAction) {
        setModalStepNumber(4);
      }
      setSuccessMessage(t("order_initiated_successfully"));
      setIsProcessing(false);
    } catch (error) {
      const errorMsg =
        error.message === "too_many_requests_try_later"
          ? t("too_many_requests_try_later")
          : t(error.message);
      setErrorMessage(errorMsg);
      setSuccessMessage("");
      setIsProcessing(false);
    } finally {
      // After successful API call, clear the Autocomplete instance
      if (!isTestAction) {
        setIsProcessing(false);
      }
    }
  };

  const step3_handleBackspaceOnSubmit = (e) => {
    if (e.key === "Backspace") {
      let lastFilledIndex = otp.findIndex(
        (value, index) => index === otp.length - 1 && value !== ""
      );
      if (lastFilledIndex === -1) {
        lastFilledIndex = otp.lastIndexOf(otp.find((value) => value !== ""));
      }

      if (lastFilledIndex >= 0) {
        setOtp((prevOtp) => {
          const newOtp = [...prevOtp];
          newOtp[lastFilledIndex] = "";
          return newOtp;
        });
        inputsRef.current[lastFilledIndex].focus();
      }
      e.preventDefault();
    }
  };

  const isOtpComplete = () => {
    return otp.length === 6 && otp.every((digit) => digit.trim() !== "");
  };

  const handleChange = (index, value) => {
    if (!/^[0-9]$/.test(value)) return;
    setOtp((prevOtp) => {
      const newOtp = [...prevOtp];
      newOtp[index] = value;
      return newOtp;
    });

    // Check if it's the last input field and the value is not empty
    if (index === otp.length - 1 && value !== "") {
      // Focus the confirmation button
      step3_submitButtonRef.current.focus();
    } else if (value !== "" && index < otp.length - 1) {
      // Focus the next OTP input field
      inputsRef.current[index + 1].focus();
    }
  };

  const handleKeyDown = (e, index) => {
    if (e.key === "Backspace") {
      if (otp[index] === "" && index > 0) {
        // Focus the previous field if the current field is empty and it's not the first field
        setOtp((prevOtp) => {
          const newOtp = [...prevOtp];
          newOtp[index - 1] = "";
          return newOtp;
        });
        inputsRef.current[index - 1].focus();
      }
    }
  };

  const handleChangeAddress = (e) => {
    const { name, value } = e.target;
    setFormValues((prevValues) => ({ ...prevValues, [name]: value }));
  };

  const handleCardTypeConfirmed = () => {
    setCardTypeConfirmed(true);
  };

  const handleCardTypeConfirmedBack = () => {
    setErrorMessage("");
    setCardTypeConfirmed(false);
  };

  const handleAcceptTermsChange = (e) => {
    setErrorMessage("");
    setAcceptTerms(e.target.checked);
  };

  const placeChangedHandler = useCallback(() => {
    const twoToThreeLetterCountryCodes = {
      AT: "AUT", // Austria
      BE: "BEL", // Belgium
      BG: "BGR", // Bulgaria
      HR: "HRV", // Croatia
      CY: "CYP", // Cyprus
      CZ: "CZE", // Czech Republic
      DK: "DNK", // Denmark
      EE: "EST", // Estonia
      FI: "FIN", // Finland
      FR: "FRA", // France
      DE: "DEU", // Germany
      GR: "GRC", // Greece
      HU: "HUN", // Hungary
      IE: "IRL", // Ireland
      IT: "ITA", // Italy
      LV: "LVA", // Latvia
      LT: "LTU", // Lithuania
      LU: "LUX", // Luxembourg
      MT: "MLT", // Malta
      NL: "NLD", // Netherlands
      PL: "POL", // Poland
      PT: "PRT", // Portugal
      RO: "ROU", // Romania
      SK: "SVK", // Slovakia
      SI: "SVN", // Slovenia
      ES: "ESP", // Spain
      SE: "SWE", // Sweden
      GB: "GBR", // United Kingdom
    };

    const convertToThreeLetterCode = (twoLetterCode) => {
      return twoToThreeLetterCountryCodes[twoLetterCode] || twoLetterCode;
    };

    const place = autocomplete.getPlace();
    if (place && place.address_components) {
      let streetName = "";
      let addressObject = {
        addr_line_1: "",
        zip_code: "",
        city: "",
        state: "",
        country: "",
      };

      let streetNumber = "";
      let route = "";

      place.address_components.forEach((component) => {
        const types = component.types;
        if (types.includes("street_number")) {
          streetNumber = component.long_name;
        } else if (types.includes("route")) {
          route = component.long_name;
        } else if (types.includes("postal_code")) {
          addressObject.zip_code = component.long_name;
        } else if (
          types.includes("locality") ||
          types.includes("administrative_area_level_2")
        ) {
          addressObject.city = component.long_name;
        } else if (types.includes("administrative_area_level_1")) {
          addressObject.state = component.long_name;
        } else if (types.includes("country")) {
          addressObject.country = component.long_name;
        }
      });

      addressObject.addr_line_1 = `${route}, ${streetNumber}`.trim();

      place.address_components.forEach((component) => {
        const types = component.types;

        if (types.includes("route")) {
          streetName = component.long_name;
        }
        if (types.includes("street_number")) {
          streetNumber = component.long_name;
        }

        if (types.includes("postal_code")) {
          addressObject.zip_code = component.long_name;
        }
        if (
          types.includes("locality") ||
          types.includes("administrative_area_level_2")
        ) {
          addressObject.city = component.long_name;
        }
        if (types.includes("administrative_area_level_1")) {
          addressObject.state = component.long_name;
        }
        if (types.includes("country")) {
          addressObject.country = convertToThreeLetterCode(
            component.short_name
          );
        }
      });

      addressObject.addr_line_1 = `${streetName} ${streetNumber}`.trim();
      setFormValues((prevValues) => ({ ...prevValues, ...addressObject }));
    }
  }, [autocomplete]);

  // Initialize Autocomplete
  useEffect(() => {
    // Ensure that this effect only runs when ModalStepNumber is 2
    if (ModalStepNumber !== 2) {
      return;
    }

    // Check if autocomplete is defined
    if (!autocomplete) {
      console.error("Autocomplete is not defined");
      return;
    }

    // Add the listener
    const listener = autocomplete.addListener(
      "place_changed",
      placeChangedHandler
    );

    // Clean up
    return () => {
      // Safely remove the listener
      if (listener && window.google && window.google.maps) {
        window.google.maps.event.removeListener(listener);
      }
    };
  }, [autocomplete, ModalStepNumber, placeChangedHandler]); // Include ModalStepNumber in the dependency array

  useEffect(() => {
    if (ModalStepNumber === 3 && inputsRef.current[0]) {
      inputsRef.current[0].focus();
    }
  }, [ModalStepNumber, DoubleAuthOrderCardExp, inputsRef]);

  const [formValues, setFormValues] = useState({
    addr_line_1: "",
    zip_code: "",
    city: "",
    state: "",
    country: "ITA",
  });

  const euCountries = [
    { name: "Austria", code: "AUT" },
    { name: "Belgium", code: "BEL" },
    { name: "Bulgaria", code: "BGR" },
    { name: "Croatia", code: "HRV" },
    { name: "Cyprus", code: "CYP" },
    { name: "Czech Republic", code: "CZE" },
    { name: "Denmark", code: "DNK" },
    { name: "Estonia", code: "EST" },
    { name: "Finland", code: "FIN" },
    { name: "France", code: "FRA" },
    { name: "Germany", code: "DEU" },
    { name: "Greece", code: "GRC" },
    { name: "Hungary", code: "HUN" },
    { name: "Ireland", code: "IRL" },
    { name: "Italy", code: "ITA" },
    { name: "Latvia", code: "LVA" },
    { name: "Lithuania", code: "LTU" },
    { name: "Luxembourg", code: "LUX" },
    { name: "Malta", code: "MLT" },
    { name: "Netherlands", code: "NLD" },
    { name: "Poland", code: "POL" },
    { name: "Portugal", code: "PRT" },
    { name: "Romania", code: "ROU" },
    { name: "Slovakia", code: "SVK" },
    { name: "Slovenia", code: "SVN" },
    { name: "Spain", code: "ESP" },
    { name: "Sweden", code: "SWE" },
    { name: "United Kingdom", code: "GBR" },
  ];

  switch (ModalStepNumber) {
    case 2:
      return (
        <>
          <Dialog open={true} onClose={onClose} maxWidth="sm" fullWidth>
            <DialogTitle className="modal_title">
              {t("review_order_details")}
            </DialogTitle>
            {errorMessage && (
              <div className="error-container_modal">
                <div className="error-icon">!</div>
                <Typography className="error-text">{errorMessage}</Typography>
              </div>
            )}
            {successMessage && (
              <div className="success-container_modal">
                <div className="success-icon">✓</div>
                <Typography className="success-text">
                  {successMessage}
                </Typography>
              </div>
            )}
            <DialogContent>
              <Table style={{ width: "100%" }}>
                <TableBody>
                  <TableRow>
                    <TableCell className="table_body">
                      <div style={{ paddingBottom: "16px" }}>
                        {" "}
                        <span>{t("selected_card_type")}: </span>
                        <span>
                          {t("card_" + selectedCardToBuy.type)}&nbsp;
                          {t("card_" + selectedCardToBuy.color)}
                        </span>
                      </div>
                      <img
                        src={
                          selectedCardToBuy.color === "white" ? cardw : cardb
                        }
                        alt={`${
                          selectedCardToBuy.color === "white"
                            ? "cardw"
                            : "cardb"
                        }`}
                        style={{
                          width: "160px",
                          height: "auto",
                        }}
                      />
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell className="table_body">
                      <span>{t("card_cost")}: </span>
                      <span>
                        {t(
                          selectedCardToBuy.type === "plastic"
                            ? "include_shipping"
                            : "included"
                        )}
                      </span>
                    </TableCell>
                  </TableRow>
                  {/* <TableRow>
                    <TableCell className="table_body">
                      <span>{t('available_balance_bank_account')}: </span>
                      <span> {number_to_italian_currency(bank_account_balance)}</span>
                    </TableCell>
                  </TableRow> */}
                </TableBody>
              </Table>
            </DialogContent>

            {selectedCardToBuy.type === "plastic" && (
              <>
                <DialogTitle className="modal_title">
                  {t("delivery_address")}
                </DialogTitle>
                <DialogContent>
                  <LoadScript
                    googleMapsApiKey={config.GOOGLE_MAPS_API_KEY_WEB}
                    libraries={libraries}
                    language={"it"}
                  >
                    <Autocomplete onLoad={setAutocomplete}>
                      <TextField
                        margin="normal"
                        name="search_address"
                        label={t("search_address")}
                        fullWidth
                        variant="outlined"
                        autoComplete="off"
                        // Removed onChange handler
                      />
                    </Autocomplete>
                    {/* ... other form fields */}
                    <TextField
                      margin="normal"
                      name="addr_line_1"
                      label={t("address_line_1")}
                      fullWidth
                      variant="outlined"
                      value={formValues.addr_line_1}
                      onChange={handleChangeAddress}
                    />
                    <TextField
                      margin="normal"
                      name="zip_code"
                      label={t("zip_code")}
                      fullWidth
                      variant="outlined"
                      value={formValues.zip_code}
                      onChange={handleChangeAddress}
                    />
                    <TextField
                      margin="normal"
                      name="city"
                      label={t("city")}
                      fullWidth
                      variant="outlined"
                      value={formValues.city}
                      onChange={handleChangeAddress}
                    />
                    <TextField
                      margin="normal"
                      name="state"
                      label={t("state")}
                      fullWidth
                      variant="outlined"
                      value={formValues.state}
                      onChange={handleChangeAddress}
                    />
                    <Select
                      style={{ width: "100%", marginTop: "15px" }}
                      id="country-select"
                      label={t("country")}
                      value={formValues.country}
                      onChange={handleChangeAddress}
                      name="country"
                    >
                      {euCountries.map((option) => (
                        <MenuItem key={option.code} value={option.code}>
                          {option.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </LoadScript>
                </DialogContent>
              </>
            )}

            <DialogActions>
              <Button
                onClick={() => restart_Order()} // Corrected function call
                variant="contained"
                color="primary"
                disabled={isProcessing}
              >
                {t("back_button")}
              </Button>
              <Button
                onClick={() => step3_handleSendOTPDoubleAuth(false)}
                variant="contained"
                color="primary"
                disabled={hasInsufficientFunds || isProcessing}
              >
                {hasInsufficientFunds ? (
                  t("not_enough_funds")
                ) : isProcessing ? (
                  <CircularProgress size={24} color="inherit" />
                ) : (
                  t("send_verification_sms")
                )}
              </Button>
            </DialogActions>
          </Dialog>
        </>
      );

    case 3:
      return getCookieValue("double_auth_otp_delivery_method") === "push" ? (
        <Modal_Waiting_Push
          onClose={onClose}
          errorMessage={errorMessage}
          isProcessing={isProcessing}
          onForceSendSMS={() => step3_handleSendOTPDoubleAuth(true)}
          onAction={() => step4_handleOrderSubmit(true)}
        />
      ) : (
        <>
          <Dialog open={true} onClose={onClose} maxWidth="sm" fullWidth>
            <DialogTitle className="modal_title">
              {t("confirm_code")}
            </DialogTitle>
            {errorMessage && (
              <div className="error-container_modal">
                <div className="error-icon">!</div>
                <Typography className="error-text">{errorMessage}</Typography>
              </div>
            )}
            <DialogContent>
              <div style={styles.step_description}>
                {t("please_enter_6_digit_otp_received_via_sms")}
              </div>
              <div className="otpInputs" style={styles.otpInputsContainer}>
                {otp.map((digit, index) => (
                  <TextField
                    key={index}
                    variant="outlined"
                    inputProps={{
                      maxLength: 1,
                      style: styles.otpInput,
                    }}
                    inputRef={(el) => (inputsRef.current[index] = el)}
                    value={digit}
                    onChange={(e) => handleChange(index, e.target.value)}
                    onKeyDown={(e) => handleKeyDown(e, index)}
                  />
                ))}
              </div>
            </DialogContent>
            <DialogActions>
              <Button
                onClick={() => step3_handleSendOTPDoubleAuth(false)}
                variant="contained"
                color="primary"
                disabled={isProcessing}
              >
                {t("resend_sms")}
              </Button>
              <Button
                ref={step3_submitButtonRef}
                onKeyDown={step3_handleBackspaceOnSubmit}
                onClick={step4_handleVerifyOTPDoubleAuth}
                variant="contained"
                color="primary"
                disabled={!isOtpComplete() || isProcessing}
              >
                {isProcessing ? (
                  <CircularProgress size={24} color="inherit" />
                ) : (
                  t("verify_otp_and_send_order")
                )}
              </Button>
            </DialogActions>
          </Dialog>
        </>
      );
    case 4:
      return (
        <>
          <Dialog open={true} onClose={onClose} maxWidth="sm" fullWidth>
            <DialogTitle className="modal_title">
              {t("order_status")}
            </DialogTitle>
            {errorMessage && (
              <div className="error-container_modal">
                <div className="error-icon">!</div>
                <Typography className="error-text">{errorMessage}</Typography>
              </div>
            )}
            {successMessage && (
              <div className="success-container_modal">
                <div className="success-icon">✓</div>
                <Typography className="success-text">
                  {successMessage}
                </Typography>
              </div>
            )}

            <DialogContent>
              {isProcessing ? (
                <div style={styles.step_description}>
                  {t("order_in_progress_do_not_close_this_window")}
                </div>
              ) : (
                <div style={styles.step_description}>
                  {t("you_can_close_this_window")}
                </div>
              )}
            </DialogContent>
            <DialogActions>
              <Button
                ref={step3_submitButtonRef}
                onClick={closeModal}
                variant="contained"
                color="primary"
                disabled={isProcessing}
              >
                {isProcessing ? (
                  <CircularProgress size={24} color="inherit" />
                ) : (
                  t("close")
                )}
              </Button>
            </DialogActions>
          </Dialog>
        </>
      );
    default:
      return (
        <>
          <Dialog
            open={true}
            onClose={isProcessing ? undefined : onClose}
            maxWidth="sm"
            fullWidth
          >
            {!cardTypeConfirmed && (
              <>
                <DialogTitle className="modal_title">
                  {t("choose_card_type")}
                </DialogTitle>
                <DialogContent>
                  {errorMessage && (
                    <div
                      className="error-container_modal"
                      style={{
                        marginTop: "0px",
                        marginLeft: "0px",
                        marginRight: "0px",
                        marginBottom: "10px",
                      }}
                    >
                      <div className="error-icon">!</div>
                      <Typography className="error-text">
                        {errorMessage}
                      </Typography>
                    </div>
                  )}
                  <div style={styles.step_description}>
                    {t("choose_card_type_description")}
                  </div>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <CardTypeTag
                      type="virtual"
                      onClick={() => handleCardTypeSelection("virtual")}
                    >
                      <Typography
                        style={{
                          ...styles.card_title,
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        {t("BAAS_CARD_VIRTUAL_title")}{" "}
                        <FontAwesomeIcon
                          icon={faMobileAlt}
                          size="2x"
                          style={{ ...styles.card_pic, marginLeft: "1rem" }}
                        />
                      </Typography>
                      <Typography sx={{ flexGrow: 1, marginTop: "24px" }}>
                        {t("BAAS_CARD_VIRTUAL_WHITE_description")}
                      </Typography>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          margin: "1rem 0",
                        }}
                      >
                        {" "}
                        {/* Center image horizontally */}
                        <img
                          src={AppleGooglePayLogos}
                          alt="Apple Pay and Google Pay are supported"
                          title="Apple Pay and Google Pay are supported"
                          width="100px"
                        />
                      </div>
                    </CardTypeTag>
                    <CardTypeTag
                      type="plastic"
                      onClick={() => handleCardTypeSelection("plastic")}
                    >
                      <Typography
                        style={{
                          ...styles.card_title,
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        {t("BAAS_CARD_PLASTIC_title")}{" "}
                        <FontAwesomeIcon
                          icon={faCreditCard}
                          size="2x"
                          style={{ ...styles.card_pic, marginLeft: "1rem" }}
                        />
                      </Typography>
                      <Typography sx={{ flexGrow: 1, marginTop: "24px" }}>
                        {t("BAAS_CARD_PLASTIC_description")}
                      </Typography>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          margin: "1rem 0",
                        }}
                      >
                        <img
                          src={AppleGooglePayLogos}
                          alt="Apple Pay and Google Pay are supported"
                          title="Apple Pay and Google Pay are supported"
                          width="100px"
                        />
                      </div>
                    </CardTypeTag>
                  </div>
                </DialogContent>
                <DialogActions>
                  {/* CLOSE BUTTON */}
                  <Button
                    onClick={onClose}
                    color="primary"
                    variant="contained"
                    sx={{ width: "100px" }}
                  >
                    {t("close")}
                  </Button>
                  <Button
                    onClick={handleCardTypeConfirmed}
                    variant="contained"
                    color="primary"
                    sx={{ width: "100px" }}
                    disabled={selectedCardToBuy.type === null}
                  >
                    {isProcessing ? (
                      <CircularProgress size={24} color="inherit" />
                    ) : (
                      t("next_button")
                    )}
                  </Button>
                </DialogActions>
              </>
            )}
            {cardTypeConfirmed && (
              <>
                <DialogTitle className="modal_title">
                  {t("choose_card_color_" + selectedCardToBuy.type)}
                </DialogTitle>
                <DialogContent>
                  {errorMessage && (
                    <div
                      className="error-container_modal"
                      style={{
                        marginTop: "0px",
                        marginLeft: "0px",
                        marginRight: "0px",
                        marginBottom: "10px",
                      }}
                    >
                      <div className="error-icon">!</div>
                      <Typography className="error-text">
                        {errorMessage}
                      </Typography>
                    </div>
                  )}
                  {successMessage && (
                    <div className="success-container_modal">
                      <div className="success-icon">✓</div>
                      <Typography className="success-text">
                        {successMessage}
                      </Typography>
                    </div>
                  )}
                  <div style={styles.step_description}>
                    {t("choose_card_color_description")}
                  </div>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <CardColorTag
                      color="white"
                      onClick={() => handleCardColorSelection("white")}
                    >
                      <Typography
                        style={{
                          ...styles.card_title,
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        <img
                          src={cardw}
                          alt="cardw"
                          style={{
                            width: "160px",
                            height: "auto",
                          }}
                        />
                      </Typography>
                      <Typography sx={{ flexGrow: 1, marginTop: "24px" }}>
                        {t("BAAS_CARD_WHITE_description")}
                      </Typography>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          margin: "1rem 0",
                        }}
                      >
                        <img
                          src={AppleGooglePayLogos}
                          alt="Apple Pay and Google Pay are supported"
                          title="Apple Pay and Google Pay are supported"
                          width="100px"
                        />
                      </div>
                    </CardColorTag>
                    <CardColorTag
                      color="blue"
                      onClick={() => handleCardColorSelection("blue")}
                    >
                      <Typography
                        style={{
                          ...styles.card_title,
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        <img
                          src={cardb}
                          alt="cardb"
                          style={{
                            width: "160px",
                            height: "auto",
                          }}
                        />
                      </Typography>
                      <Typography sx={{ flexGrow: 1, marginTop: "24px" }}>
                        {t(
                          hasBlueCard
                            ? "BAAS_CARD_BLUE_active_description"
                            : "BAAS_CARD_BLUE_inactive_description"
                        )}
                      </Typography>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          margin: "1rem 0",
                        }}
                      >
                        <img
                          src={AppleGooglePayLogos}
                          alt="Apple Pay and Google Pay are supported"
                          title="Apple Pay and Google Pay are supported"
                          width="100px"
                        />
                      </div>
                    </CardColorTag>
                  </div>
                </DialogContent>
                <DialogActions>
                  {/* TERMS AND CONDITIONS CHECKBOX */}
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                      flexGrow: 1,
                      marginRight: "4px",
                    }}
                  >
                    <Checkbox
                      checked={acceptTerms}
                      onChange={handleAcceptTermsChange}
                      color="primary"
                    />
                    <Typography variant="body2" className="terms">
                      <a
                        href={`${window.location.origin}/docs/termini-${
                          getCookieValue("language") === "en" ? "en" : "it"
                        }${isBusinessAccount() ? "-business" : ""}.html`}
                        className="terminicondizioni"
                        onClick={() => {}}
                        without
                        rel="noreferrer"
                        target="_blank"
                      >
                        {t("accept_terms_order_card")}
                      </a>
                    </Typography>
                  </div>

                  {/* BACK BUTTON */}
                  <Button
                    onClick={handleCardTypeConfirmedBack}
                    color="primary"
                    variant="contained"
                    sx={{ width: "100px" }}
                    disabled={isProcessing}
                  >
                    {t("back_button")}
                  </Button>
                  {selectedCardToBuy.type && (
                    <Button
                      onClick={step2_review_order_details}
                      variant="contained"
                      color="primary"
                      disabled={
                        isProcessing || !selectedCardToBuy.color || !acceptTerms
                      }
                      sx={{ width: "100px" }}
                    >
                      {isProcessing ? (
                        <CircularProgress size={24} color="inherit" />
                      ) : (
                        t("next_button")
                      )}
                    </Button>
                  )}
                </DialogActions>
              </>
            )}
          </Dialog>
        </>
      );
  }
}

export default Modal_StartCardOrder;
